import { useState } from 'react';
import { Box, Divider, Fab, Paper, Tooltip, Typography, Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import LogoApp from 'src/components/LogoApp';
import axios from 'src/lib/axios';
import parseUrl from 'src/utils/urlParser';
import Form from './Form';

const Feedback = () => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const environment = parseUrl();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (values) => {
    try {
      const formData = new FormData();
      formData.append('file', values.file);
      formData.append('comment', values.comment);
      formData.append('option', values.option);
      await axios.post('users/feedback', formData);
      enqueueSnackbar('Mensaje enviado con éxito', {
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom'
        },
        variant: 'success'
      });
      setOpen(false);
    } catch (error) {
      enqueueSnackbar('Hubo un error al enviar el mensaje, intente nuevamente', {
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom'
        },
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Tooltip title="¿Necesitas Ayuda?">
        <Fab
          color="primary"
          onClick={handleOpen}
          size="medium"
          sx={{
            bottom: 0,
            margin: (theme) => theme.spacing(4),
            position: 'fixed',
            right: 0,
            zIndex: (theme) => theme.zIndex.speedDial
          }}
        >
          <Typography variant="h5">?</Typography>
        </Fab>
      </Tooltip>
      <Dialog open={open} fullWidth maxWidth="sm">
        <Paper sx={{ px: { md: 6 }, pt: { md: 6 }, pb: { md: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography>
              <LogoApp img="logo.png" height={80} width={environment === 'cavem' ? 80 : 180} />
            </Typography>
            <Divider sx={{ width: '100%', my: 2 }} />
            <Typography color="#d80d1f" variant="h6" letterSpacing={4}>
              <strong>SOPORTE</strong>
            </Typography>
            <Typography align="center" color="textSecondary" sx={{ mt: 1 }} variant="body2">
              Escribe tus dudas, reclamos o comentarios en el siguiente formulario
            </Typography>
          </Box>
          <Form handleClose={handleClose} handleSave={handleSave} />
        </Paper>
      </Dialog>
    </>
  );
};

export default Feedback;
