import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 500) {
      return window.location.reload();
    }
    return Promise.reject((error.response && error.response.data) || 'Ocurrió un error al comunicarse con el servidor');
  }
);

export default axiosInstance;
