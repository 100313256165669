import { Box } from '@material-ui/core';
import parseUrl from 'src/utils/urlParser';
import LogoApp from './LogoApp';

const SlashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000
    }}
  >
    <LogoApp img="logo2.png" height={parseUrl() === 'cavem' ? 100 : 150} width={300} />
  </Box>
);

export default SlashScreen;
